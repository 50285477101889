/* bootstrap overwrite */
.pages-search-list-component .search-container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pages-search-list-component .search-container .search-form {
  background-color: white;
  border: 1px solid #708392;
}
.pages-search-list-component .search-container .search-form input {
  border: none;
  z-index: 1;
}
.pages-search-list-component .search-container .search-form button {
  z-index: 2;
}
.pages-search-list-component .search-container .search-form button svg {
  width: 16px;
  height: 16px;
}
.pages-search-list-component .search-container .search-form button:focus,
.pages-search-list-component .search-container .search-form input:focus {
  box-shadow: 0 0 0 0.2rem #c9d1e3;
}
.pages-search-list-component .results-container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pages-search-list-component .results-container .pages .page {
  margin-top: 20px;
}
.pages-search-list-component .results-container .pages .page:first-child {
  margin-top: 0;
}
.pages-search-list-component .results-container .no-content .empty-card {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #657785;
}
.pages-search-list-component .results-container .no-content .empty-card .svg-icon {
  color: #657785;
}
.pages-search-list-component .teaser.dsft-teaser .teaser-image {
  width: 100%;
  overflow: hidden;
}
.pages-search-list-component .teaser.dsft-teaser .teaser-image img {
  aspect-ratio: 6/4;
  object-fit: cover;
  transform: scale(1);
  transition: all 0.5s linear;
}
.pages-search-list-component .teaser.dsft-teaser .teaser-title:hover {
  cursor: pointer;
}
.pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons {
  right: 0;
  bottom: 0;
}
.pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons .teaser-accessibility-icon {
  height: 26px;
  width: 26px;
}
@media (min-width: 768px) {
  .pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons .teaser-accessibility-icon {
    height: 40px;
    width: 40px;
  }
}
.pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons .teaser-accessibility-icon .svg-icon {
  height: 26px;
  width: 26px;
  color: currentColor;
}
@media (min-width: 768px) {
  .pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons .teaser-accessibility-icon .svg-icon {
    height: 40px;
    width: 40px;
  }
}
.pages-search-list-component .teaser.dsft-teaser .teaser-accessibility-icons .teaser-accessibility-icon .svg-icon.is-info {
  left: 0;
}
.pages-search-list-component .teaser.dsft-teaser .dsft-icon-separator:last-of-type {
  display: none;
}
.pages-search-list-component .teaser.dsft-teaser:hover .teaser-image img {
  transform: scale(1.1);
}
.pages-search-list-component .teaser.dsft-teaser:hover .teaser-tagline {
  background: transparent;
  color: var(--text-color);
}
.pages-search-list-component .teaser.dsft-teaser:hover .teaser-link {
  color: var(--accent-hover-color);
}
@media (prefers-reduced-motion: reduce) {
  .pages-search-list-component .teaser.dsft-teaser:hover .teaser-link {
    text-decoration: underline;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .pages-search-list-component .teaser.dsft-teaser:hover .teaser-link {
    text-decoration: none;
    background-size: 100% 1px;
  }
}

.main-area .section-spacer:first-child .pages-search-list-component {
  margin-top: -32px;
}
@media (min-width: 992px) {
  .main-area .section-spacer:first-child .pages-search-list-component {
    margin-top: -96px;
  }
}