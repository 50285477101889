@import 'webresources-sources/scss/imports';

.pages-search-list-component {
  .search-container {
    padding-top: 80px;
    padding-bottom: 80px;

    .search-form {
      background-color: white;
      border: 1px solid #708392;

      input {
        border: none;
        z-index: 1;
      }

      button {
        z-index: 2;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      button,
      input {
        &:focus {
          box-shadow: 0 0 0 0.2rem #c9d1e3;
        }
      }
    }
  }

  .results-container {
    padding-top: 80px;
    padding-bottom: 80px;

    .pages {
      .page {
        margin-top: $grid-gutter-width;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .no-content {
      .empty-card {
        background-color: $color-white;
        padding-top: 80px;
        padding-bottom: 80px;
        color: $color-gray-600;

        .svg-icon {
          color: $color-gray-600;
        }
      }
    }
  }

  .teaser {
    &.dsft-teaser {
      .teaser-image {
        width: 100%;
        overflow: hidden;

        img {
          aspect-ratio: 6 / 4;
          object-fit: cover;
          transform: scale(1);
          transition: all 0.5s linear;
        }
      }

      .teaser-title {
        &:hover {
          cursor: pointer;
        }
      }

      .teaser-accessibility-icons {
        right: 0;
        bottom: 0;

        .teaser-accessibility-icon {
          height: 26px;
          width: 26px;

          @include media-breakpoint-up(md) {
            height: 40px;
            width: 40px;
          }

          .svg-icon {
            height: 26px;
            width: 26px;
            color: currentColor;

            @include media-breakpoint-up(md) {
              height: 40px;
              width: 40px;
            }

            &.is-info {
              left: 0;
            }
          }
        }
      }

      .dsft-icon-separator:last-of-type {
        display: none;
      }

      &:hover {
        .teaser-image {
          img {
            transform: scale(1.1);
          }
        }

        .teaser-tagline {
          background: transparent;
          color: var(--text-color);
        }

        .teaser-link {
          color: var(--accent-hover-color);

          @media (prefers-reduced-motion: reduce) {
            text-decoration: underline;
          }

          @media (prefers-reduced-motion: no-preference) {
            text-decoration: none;
            background-size: 100% 1px;
          }
        }
      }
    }
  }
}

.main-area {
  .section-spacer {
    &:first-child {
      .pages-search-list-component {
        margin-top: -32px;

        @include media-breakpoint-up(lg) {
          margin-top: -96px;
        }
      }
    }
  }
}
